import { createMessage } from '@formkit/core'
import { useAlertBanner } from '~/composables/shared/useAlertBanner'

export const flattenObject = (obj: any) => {
  const result: { [key: string]: any } = {}
  for (const i in obj) {
    if (typeof obj[i] === 'object' && !(Array.isArray(obj[i]) && obj[i].every((i: any) => typeof i === 'string'))) {
      if (Array.isArray(obj[i])) {
        obj[i].forEach((item, index) => {
          const flattenedObj = flattenObject(item)
          for (const j in flattenedObj) {
            result[i + '.' + index + '.' + j] = flattenedObj[j]
          }
        })
      } else {
        const flattenedObj = flattenObject(obj[i])
        for (const j in flattenedObj) {
          result[i + '.' + j] = flattenedObj[j]
        }
      }
    } else {
      result[i] = obj[i]
    }
  }
  return result
}

export const createIndexKeyMessage = (messages: any[]) => {
  return (messages || []).map((message: any, key: any) =>
    createMessage({
      key,
      type: 'error',
      value: message,
      meta: { autoClear: true }
    })
  )
}

export const handleFormError = (node: any, error: any) => {
  const messageObj = flattenObject(error)
  const nonFieldErrors = messageObj.non_field_errors
  const allFieldErrors = messageObj.__all__
  if (nonFieldErrors || allFieldErrors) {
    const alertBanner = useAlertBanner()
    nonFieldErrors?.forEach((message: string) => {
      alertBanner.add({
        description: message,
        color: 'red'
      })
    })
  }
  allFieldErrors?.forEach((message: string) => {
    const alertBanner = useAlertBanner()
    alertBanner.add({
      description: message,
      color: 'red'
    })
  })
  Object.keys(messageObj).forEach((key) => {
    if (key !== 'non_field_errors' && key !== '__all__') {
      const childNode = node.children.find((child: { name: string }) => child.name === key)
      if (childNode) childNode.store.apply(createIndexKeyMessage(messageObj[key]))
    }
  })
}

export const handlePhaseFormError = (nodes: any[], error: any) => {
  let firstErrNode: any = null // 첫 번째 에러 노드를 저장할 변수
  let firstErrPhase: number | null = null // 첫 번째 에러 페이즈를 저장할 변수
  const messageObj = flattenObject(error)

  Object.keys(messageObj).forEach((key) => {
    for (let i = 0; i < nodes.length; i++) {
      const childNode = nodes[i].children.find((child: { name: string }) => child.name === key)
      if (childNode) {
        childNode.store.apply(createIndexKeyMessage(messageObj[key]))
        if (!firstErrNode) {
          firstErrNode = childNode
        }
        if (!firstErrPhase) {
          firstErrPhase = i + 1
        }
      }
    }
  })

  return { firstErrNode, firstErrPhase } // 첫 번째 에러 노드이름 반환
}
